import { useEffect } from "react";
import ReactGA from "react-ga4";

import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useGetChallengeInfo } from "~/hooks";
import NewHomePagePresenter from "~/presenter/NewHomePagePresenter";

export default () => {
  // hooks
  const location = useLocation();

  const { endDuration } = useGetChallengeInfo();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, []);
  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#484848" />
        <title>Pipit | 함께하는 기상 인증 SNS</title>
        {/* <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="Pipit | 함께하는 기상 인증 SNS" />
        <meta
          name="description"
          content={"혼자 일어나시나요?\nPipit에서 성공적인 아침을 함께 보내요!"}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://alarm-app.s3.ap-northeast-2.amazonaws.com/site/large_2.jpg"
        />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" /> */}
      </Helmet>
      <NewHomePagePresenter endDuration={endDuration} />
    </>
  );
};

import React from "react";
import { colors } from "~/constants";
import { classNames } from "~/utils";

interface Props {
  value: string;
  unit: string;
  isDark: boolean;
}

export default ({ value, unit, isDark }: Props) => {
  return (
    <div
      className={classNames(
        "py-[4px] px-[9px]  rounded-[8px]",
        isDark ? "border-pipit border" : "border-white-m"
      )}
      style={{
        backgroundColor: isDark ? colors.black.back : colors.white.back,
      }}
    >
      <p className="text-[20px] text-pipit font-bold font-gmarket">
        {value + unit}
      </p>
    </div>
  );
};

import { useEffect, useRef, useState } from "react";
import { JoinChallengeInfo, Status } from "~/container/ChallengeContainer";
import { imgUtil } from "~/images/image_container";
import { base64toFile, CaptureImg } from "~/utils/capture";
import { gaEvent } from "~/utils/ga";
import { openInstagram } from "~/utils/share";
import { useCreatePostData } from "./useCreatePostData";
import { useUpload } from "./useUpload";

interface Props {
  openAlarmModal: () => void;
}
interface Output {
  // vals
  joinInfoMap: Map<Status, JoinChallengeInfo>;
  // states
  status: Status;
  setStatus: React.Dispatch<React.SetStateAction<Status>>;
  cardImg: string | null;
  _setCardImg: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedImg: (file: File | undefined) => void;
  imgInputRef: React.RefObject<HTMLInputElement>;
}

export const usePosting = ({ openAlarmModal }: Props) => {
  // hooks
  const { uploadToS3 } = useUpload();
  const { createPostData } = useCreatePostData();

  // ref
  const imgInputRef = useRef<HTMLInputElement>(null);

  // states
  const [status, setStatus] = useState<Status>("NOT_OPENED");
  const [cardImg, _setCardImg] = useState<string | null>(null);

  // funcs
  const inputImgFile = () => imgInputRef.current?.click();
  const setSelectedImg = (file: File | undefined) => {
    if (!file) {
      return;
    }
    const localImgUrl = URL.createObjectURL(file);
    _setCardImg(localImgUrl);
  };

  useEffect(() => {
    if (
      cardImg &&
      cardImg !== imgUtil.default_post_img &&
      cardImg !== imgUtil.test_img &&
      status !== "OUT_OF_TIME"
    ) {
      if (status === "BEFORE_POST") {
        setStatus("AFTER_POST");
      }
      CaptureImg("card")
        .then((base64) => {
          const file = base64toFile(base64, "timeStamp.png");
          return uploadToS3({ file, type: "image/png", extension: ".png" });
        })
        .then((e) => {
          return createPostData(e);
        })
        // .then(() => alert("인증 성공!"))
        .catch(() => setStatus("BEFORE_POST"));
    }
  }, [cardImg]);

  useEffect(() => {
    if (status === "NOT_OPENED") {
      _setCardImg(imgUtil.test_img);
    } else if (status === "OUT_OF_TIME") {
      _setCardImg(imgUtil.timeout_post_img);
    } else if (status === "BEFORE_POST") {
      _setCardImg(imgUtil.default_post_img);
    }
  }, [status]);

  // vals
  const joinInfoMap = new Map<Status, JoinChallengeInfo>([
    [
      "NOT_OPENED",
      {
        buttonText: "인증 시간에 알림받기",
        iconSrc: imgUtil.icons.bell,
        onClick: () => {
          openAlarmModal(),
            gaEvent({
              status,
              category: "Challenge",
              action: `open_alarm_modal`,
            });
        },
        card_default_text: "나도 했다 #미라클모닝",
        isTimeStampVisible: true,
        isTransparent: true,
        isNoTextBg: false,
      },
    ],
    [
      "OUT_OF_TIME",
      {
        buttonText: "인증 시간에 알림받기",
        iconSrc: imgUtil.icons.bell,
        onClick: () => {
          openAlarmModal(),
            gaEvent({
              status,
              category: "Challenge",
              action: `open_alarm_modal`,
            });
        },
        card_default_text: "나도 했다 #미라클모닝",
        isTimeStampVisible: true,
        isTransparent: true,
        isNoTextBg: false,
      },
    ],
    [
      "BEFORE_POST",
      {
        buttonText: "사진 업로드",
        iconSrc: imgUtil.icons.add_img,
        onClick: inputImgFile,
        card_default_text: "나도 했다 #미라클모닝",
        isTimeStampVisible: true,
        isTransparent: false,
        isNoTextBg: false,
      },
    ],
    [
      "AFTER_POST",
      {
        buttonText: "미라클모닝하는 사람들 만나보기",
        iconSrc: imgUtil.logo,
        onClick: () => {
          gaEvent({
            status,
            category: "Challenge",
            action: `click_meet_miracle_morning`,
          });
          openInstagram();
        },
        card_default_text: "나도 했다 #미라클모닝",
        isTimeStampVisible: true,
        isTransparent: false,
        isNoTextBg: true,
      },
    ],
  ]);

  return {
    joinInfoMap,
    status,
    setStatus,
    cardImg,
    _setCardImg,
    setSelectedImg,
    imgInputRef,
  };
};

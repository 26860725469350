import html2canvas from "html2canvas";
import { reject } from "lodash";

export default async () => {
  const downloadImage = (blob: string, fileName: string) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style.display = "none";
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  const element = document.getElementById("card");
  if (element) {
    const canvas = await html2canvas(element, {
      backgroundColor: "#FFFFFF",
    });
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, "Pipit 미라클모닝 챌린지");
  } else {
    alert("ERROR");
  }
};

export const CaptureImg = async (elementId: string) => {
  return new Promise<string>(async (resolve, reject) => {
    const element = document.getElementById(elementId);
    if (!element) {
      reject(`No Element with such Id: ${elementId}`);
      return;
    }
    const canvas = await html2canvas(element, {
      backgroundColor: "#FFFFFF",
    });
    const image = canvas.toDataURL("image/png", 1.0);
    resolve(image);
    // resolve(new File([image], "timeStamp.png", { type: "image/png" }));
  });
};

export const base64toFile = (base64: string, fileName: string) => {
  var arr = base64.split(","),
    mime = arr[0].match(/:(.*?);/)?.[1] ?? "text/plain",
    bstr = window.atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

import React, { useState } from "react";

import { exportComponentAsPNG } from "react-component-export-image";
import { Dayjs } from "dayjs";
import { imgUtil } from "~/images/image_container";
import Margin from "./Margin";
import { colors } from "~/constants";
import { TranslucenceButton } from "./Buttons";

interface Props {
  imageSrc?: string;
  text: string;
  moment: Dayjs;
  style?: React.CSSProperties;
  isTimeStampVisible: boolean;
  isTransparent: boolean;
  isNoTextBg: boolean;
}

export default ({
  imageSrc: imageSrcProp,
  text,
  moment,
  style,
  isTimeStampVisible,
  isTransparent,
  isNoTextBg,
}: Props) => {
  const [isTimeColorDark, setIsTimeColorDark] = useState<boolean>(false);
  const toggleTimeColor = () => setIsTimeColorDark(() => !isTimeColorDark);
  const imageSrc = imageSrcProp
    ? imageSrcProp
    : (imgUtil.default_post_img as string);
  const cardRef = React.useRef<HTMLDivElement>(null);
  return (
    <div
      id="card"
      ref={cardRef}
      className="flex relative w-[300px] h-[400px]  "
      style={{ ...style, lineHeight: 1 }}
    >
      {/* background image */}
      <img
        src={
          isTransparent
            ? imgUtil.post_background_80
            : isNoTextBg
            ? imgUtil.post_background_no_text_bg
            : imgUtil.post_background
        }
        className="absolute flex w-full h-full"
      />
      {/* img */}
      <div className="flex top-[54px] absolute w-full justify-center">
        <div className="flex relative  w-[246px] h-[246px]  rounded-[3px] overflow-hidden">
          <div className="flex w-full h-full" onClick={toggleTimeColor}>
            {/* 사용자 이미지 */}
            <div className="flex items-center justify-center object-cover overflow-hidden">
              <img
                src={imageSrc}
                className="object-cover"
                style={{ width: 246, height: 246, objectFit: "cover" }}
              />
            </div>
            {/* 현재 시간 */}
            <div className="absolute flex top-[12.5px] left-[12.5px] ">
              <img src={imgUtil.logo_text} className="h-[14.5px] w-[43px]" />
            </div>
            {isTimeStampVisible && (
              <div
                className="absolute flex bottom-[19px] left-[13px] flex-col  "
                style={{ color: isTimeColorDark ? "#262626" : "#FFFFFF" }}
              >
                <p className=" font-gmarket text-[10px]">
                  {moment.format("YYYY.MM.DD ddd") + " "}
                </p>
                <Margin margin={10} />
                <div className="flex flex-row items-end">
                  <div className="flex flex-1 text-right text-[30px] ">
                    <p className=" font-gmarket">
                      {moment.format("hh:mm") + " "}
                    </p>
                  </div>
                  <div className="flex flex-1 text-[20px] items-end pb-[2px] ">
                    <p className=" font-gmarket">{moment.format("A")}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* 하단 텍스트 문구 */}
      <div className="flex bottom-[34px] absolute w-full justify-center">
        <div className="flex w-[246px] h-[50px]  rounded-[3px] items-center justify-center overflow-hidden  ">
          <div
            id="card_1"
            className="flex relative max-w-[196px] overflow-hidden "
          >
            <p className="font-Mongdol text-[24px] whitespace-nowrap text-black-d  text-center">
              {text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

import dayjs, { Dayjs } from "dayjs";
import React, { forwardRef } from "react";
import Lottie from "react-lottie";

import { colors } from "~/constants";
import { JoinChallengeInfo, Status } from "~/container/ChallengeContainer";
import { imgUtil } from "~/images/image_container";
import {
  Card,
  Margin,
  TimeElement,
  IconButton,
  RoundButton,
  RoundButtonBorder,
  TranslucenceButton,
  CustomFade,
} from "~/components";
import capture from "~/utils/capture";
import { classNames } from "~/utils";
import { lottieUtil } from "~/lotties/lottie_container";

export const ParticipantsCard = ({ peopleNum }: { peopleNum: number }) => {
  return (
    <div className="relative flex flex-col w-full">
      {/* illust */}
      <img
        src={imgUtil.illust.sun}
        className="flex absolute z-[0] top-0 left-0 w-[85px] h-[85px] "
      />
      <img
        src={imgUtil.illust.cloud_skyblue}
        className="flex absolute z-[2] top-[25px] right-[10px] w-[60px] h-[60px] "
      />
      <img
        src={imgUtil.illust.cloud_blue}
        className="flex absolute z-[3] top-[10px] right-[27px] w-[60px] h-[60px] "
      />
      <img
        src={imgUtil.illust.pipit}
        className="flex absolute z-[3] bottom-[-12px] right-[1px] w-[60px] h-[60px] "
      />
      {/* text */}
      <div className="flex z-[1] flex-col items-center mt-[48px] py-[24px] w-full rounded-[10px] bg-white-l border border-white-m  border-dashed">
        <div className="flex flex-row justify-center ">
          <p className="text-[22px] text-pipit font-black">{`${peopleNum}명`}</p>
          <p className="text-[22px] text-black-d font-bold">이</p>
        </div>
        <p className="text-[22px] text-black-d font-bold">
          보람찬 아침을 맞았어요!
        </p>
      </div>
    </div>
  );
};

interface JoinChallengeProps {
  isDark: boolean;
  peopleNum: number;
  status: Status;
  targetDate: Dayjs;
  joinInfoMap: Map<Status, JoinChallengeInfo>;
  imgInputRef: React.RefObject<HTMLInputElement>;
  setSelectedImg: (file: File | undefined) => void;
  cardImg: string | null;
  now: Dayjs;
}

export const JoinChallenge = forwardRef(
  (
    {
      isDark,
      status,
      targetDate,
      peopleNum,
      joinInfoMap,
      imgInputRef,
      setSelectedImg,
      cardImg,
      now,
    }: JoinChallengeProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const remainTime = dayjs.duration(targetDate.diff(now));
    return (
      <div
        ref={ref}
        className="flex w-full relative flex-col items-center px-[16px] z-30 "
        style={{
          backgroundColor: isDark ? "#000000" : colors.white.l,
        }}
      >
        <input
          ref={imgInputRef}
          style={{ display: "none" }}
          type={"file"}
          accept="image/jpg, image/jpeg, image/png"
          onChange={(e) => {
            setSelectedImg(e.target.files?.[0]);
          }}
        />
        {/* 팡파레 */}
        {status === "AFTER_POST" && (
          <div className="absolute w-[375px] h-full overflow-hidden ">
            <div className="flex absolute z-[0] top-[-47px] right-[-125px]">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: lottieUtil.fireworks,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                width={346}
                height={346}
              />
            </div>
            <div className="flex absolute z-[0] top-[263px] left-[-67px]">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: lottieUtil.fireworks,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                width={244}
                height={244}
              />
            </div>
            <div className="flex absolute z-[0] bottom-[-21px] right-[-116px]">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: lottieUtil.fireworks,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                width={407}
                height={407}
              />
            </div>
          </div>
        )}

        <div className="z-[1] flex flex-col items-center w-full ">
          <Margin margin={50} />
          <CustomFade>
            {status === "NOT_OPENED" || status === "OUT_OF_TIME" ? (
              <div className="flex flex-col items-center w-full">
                <Margin margin={96} />
                <p
                  className={classNames(
                    "text-[20px] font-gmarket",
                    isDark ? "text-white-l/50" : "text-black-d/50"
                  )}
                >
                  Pipit 미라클모닝 챌린지
                </p>
                <Margin margin={16} />
                <div className="flex flex-row">
                  <p className="text-pipit text-[22px] font-gmarket font-bold  whitespace-pre">
                    {"4시~9시 "}
                  </p>
                  <p className="text-white-l text-[22px] font-gmarket font-bold  whitespace-pre">
                    사이에만 인증하면 성공👍
                  </p>
                </div>
                <Margin margin={34} />
              </div>
            ) : status === "BEFORE_POST" ? (
              // BEFORE POST
              <div className="flex flex-col items-center w-full">
                <Margin margin={96} />
                <p
                  className={"text-[20px] font-gmarket text-black-d font-bold"}
                >
                  Pipit 미라클모닝 챌린지
                </p>
                <Margin margin={20} />
                <div className="flex flex-row items-center">
                  <p className="text-[14px] text-white-d font-gmarket">
                    남은시간
                  </p>
                  <Margin isHorizontal margin={2} />
                  <img src={imgUtil.icons.time} className="w-[18px] h-[18px]" />
                </div>
                <Margin margin={10} />
                <div className="flex w-full px-[5px] justify-around">
                  {remainTime.format("D") !== "0" && (
                    <TimeElement
                      value={remainTime.format("D")}
                      unit={"일"}
                      isDark={isDark}
                    />
                  )}
                  <TimeElement
                    value={remainTime.format("HH")}
                    unit={"시간"}
                    isDark={isDark}
                  />
                  <TimeElement
                    value={remainTime.format("mm")}
                    unit={"분"}
                    isDark={isDark}
                  />
                  <TimeElement
                    value={remainTime.format("ss")}
                    unit={"초"}
                    isDark={isDark}
                  />
                </div>
                <Margin margin={20} />
              </div>
            ) : (
              // AFTER POST
              <div className="flex flex-col items-center w-full">
                <Margin margin={80} />
                <div className="flex flex-col items-center justify-center">
                  <div className="flex flex-row items-center">
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: lottieUtil.sun,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      width={43}
                      height={43}
                    />

                    <p className="font-bold text-[32px] text-black-d">
                      미라클 모닝 성공
                    </p>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: lottieUtil.sun,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      width={43}
                      height={43}
                    />
                  </div>
                  <Margin margin={4} />
                  <p className="text-[12px] text-white-d">
                    *타임스탬프를 터치하면 색상이 변경돼요
                  </p>
                </div>
                <Margin margin={26} />
              </div>
            )}
          </CustomFade>

          <CustomFade>
            <div className="flex items-center justify-center w-full ">
              <div
                className={classNames(
                  status === "NOT_OPENED" || status === "OUT_OF_TIME"
                    ? "blur-sm"
                    : ""
                )}
              >
                <Card
                  imageSrc={cardImg ?? imgUtil.transparent}
                  text={joinInfoMap.get(status)?.card_default_text ?? ""}
                  moment={dayjs()}
                  isTimeStampVisible={
                    joinInfoMap.get(status)?.isTimeStampVisible ?? true
                  }
                  isNoTextBg={joinInfoMap.get(status)?.isNoTextBg ?? false}
                  isTransparent={
                    joinInfoMap.get(status)?.isTransparent ?? false
                  }
                />
              </div>
              {/* 시간 남은 것 보여주는 모달 */}
              {(status === "NOT_OPENED" || status === "OUT_OF_TIME") && (
                <div className="absolute flex items-center justify-center w-full h-full ">
                  <div className="flex flex-col items-center py-[32px] px-[15px] shadow-md bg-black-d w-full rounded-[10px] ">
                    {status === "NOT_OPENED" ? (
                      <div className="flex flex-row">
                        <p className="text-[14px] font-gmarket text-white-l">
                          챌린지 OPEN까지
                        </p>
                        <Margin isHorizontal margin={2} />
                        <img
                          className="w-[18px] h-[18px]"
                          src={imgUtil.icons.time}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col items-center">
                        <img
                          src={imgUtil.illust.oops_pipit}
                          className="w-[71px] h-[86px]"
                        />
                        <Margin margin={24} />
                        <p className="text-[14px] font-gmarket text-white-l">
                          오늘은 인증이 종료되었어요!
                        </p>
                        <p className="text-[14px] font-gmarket text-white-l">
                          (인증 오픈시간 4:00AM~9:00AM )
                        </p>
                      </div>
                    )}
                    <Margin margin={24} />
                    <div className="flex w-full px-[5px] justify-around">
                      {/* NOT OPENED & OUT_OF_TIME */}
                      {status !== "OUT_OF_TIME" &&
                        remainTime.format("D") !== "0" && (
                          <TimeElement
                            value={remainTime.format("D")}
                            unit={"일"}
                            isDark={isDark}
                          />
                        )}
                      <TimeElement
                        value={remainTime.format("HH")}
                        unit={"시간"}
                        isDark={isDark}
                      />
                      <TimeElement
                        value={remainTime.format("mm")}
                        unit={"분"}
                        isDark={isDark}
                      />
                      <TimeElement
                        value={remainTime.format("ss")}
                        unit={"초"}
                        isDark={isDark}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {status !== "AFTER_POST" ? (
              <Margin margin={38} />
            ) : (
              <div className="flex flex-col ">
                <Margin margin={16} />
                <div className="flex flex-row">
                  <TranslucenceButton
                    onClick={() => imgInputRef.current?.click()}
                    text={"이미지 수정"}
                    iconSrc={imgUtil.icons.img}
                  />
                  <Margin isHorizontal margin={20} />
                  <TranslucenceButton
                    onClick={capture}
                    text={"이미지 저장"}
                    iconSrc={imgUtil.icons.download}
                  />
                </div>

                <Margin margin={31} />
              </div>
            )}
          </CustomFade>
          <CustomFade>
            <div className="flex flex-row">
              <p className="text-[16px] text-pipit">{`${peopleNum}명`}</p>
              <p
                className="text-[16px]"
                style={{ color: isDark ? colors.white.l : colors.black.d }}
              >
                {`이 미라클모닝 ${
                  status === "NOT_OPENED" ? "대기 중" : "성공"
                } 🔥`}
              </p>
            </div>
            <Margin margin={7} />

            {status !== "AFTER_POST" ? (
              <RoundButton
                text={joinInfoMap.get(status)?.buttonText ?? "-"}
                iconSrc={
                  joinInfoMap.get(status)?.iconSrc ?? imgUtil.transparent
                }
                onClick={joinInfoMap.get(status)?.onClick ?? (() => null)}
                isStationary={status === "BEFORE_POST"}
              />
            ) : (
              <RoundButtonBorder
                text={joinInfoMap.get(status)?.buttonText ?? "-"}
                iconSrc={
                  joinInfoMap.get(status)?.iconSrc ?? imgUtil.transparent
                }
                onClick={joinInfoMap.get(status)?.onClick ?? (() => null)}
                backgroundColor={colors.white.l}
              />
            )}
          </CustomFade>
          <Margin margin={60} />
          {/* {status !== "BEFORE_POST" && (
            <CustomFade>
              <div className="flex flex-row justify-between w-[116px] items-center">
                <IconButton
                  iconSrc={
                    isDark ? imgUtil.icons.kakao : imgUtil.icons.kakao_black
                  }
                  onClick={() => alert("kakao icon button")}
                />
                <div className="h-[16px] w-[0.5px] bg-black-l" />
                <IconButton
                  iconSrc={
                    isDark ? imgUtil.icons.link : imgUtil.icons.link_black
                  }
                  onClick={() => alert("link icon button")}
                />
              </div>
            </CustomFade>
          )} */}
          {/* <div className="flex flex-row items-center justify-center w-full">
            <p className="text-[12px] text-black-l">
              스크롤해서 이벤트 참여방법 보기
            </p>
            <Margin isHorizontal margin={4} />
            <img
              src={imgUtil.icons.dark_re_triangle}
              className="w-[9px] h-[9px]"
            />
          </div> */}
          {/* <Margin margin={32} /> */}
        </div>
      </div>
    );
  }
);

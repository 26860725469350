/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { classNames } from "~/utils";

export interface DropdownItems {
  text: string;
  onClick: () => void;
}

interface Props {
  title: string;
  isPlaceHolderVisible: boolean;
  dropdownItems: DropdownItems[];
}

export default ({ title, dropdownItems, isPlaceHolderVisible }: Props) => {
  return (
    <Menu as="div" className="relative inline-block w-full text-left">
      <div className="flex w-full">
        <Menu.Button
          className={classNames(
            "text-[14px] whitespace-nowrap   text-center border-0 font-regular flex flex-row items-center justify-center bg-white-m px-[10px] rounded-[8px] py-[10px] w-full",
            isPlaceHolderVisible ? "text-white-d" : "text-black-d"
          )}
        >
          {title}
          <div className="flex absolute right-[12px]">
            <ChevronDownIcon className="w-5 h-5 " aria-hidden="true" />
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-full mt-2 origin-top-right rounded-md shadow-lg outline-none bg-white-l ring-1 ring-black ring-opacity-5 focus:outline-none h-[250px] overflow-scroll">
          {dropdownItems.map((e) => {
            const { text, onClick } = e;
            return (
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={onClick}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm w-full "
                      )}
                    >
                      {text}
                    </button>
                  )}
                </Menu.Item>
              </div>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

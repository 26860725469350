import React, { ReactNode, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { CheckBox, Margin, RoundButton } from "~/components";
import Dropdown, { DropdownItems } from "~/components/Dropdown";
import { colors } from "~/constants";
import { imgUtil } from "~/images/image_container";
import { classNames } from "~/utils";

interface Props {
  phoneNumber: string;
  isOpen: boolean;
  onCancel: () => void;
  setPhoneNumber: (v: string) => void;
  dropdownItems: DropdownItems[];
  alarmTime: number | null;
  onComplete: () => void;
}

export default ({
  phoneNumber,
  isOpen,
  setPhoneNumber,
  onCancel,
  dropdownItems,
  alarmTime,
  onComplete,
}: Props) => {
  // states
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [isClear, setIsClear] = useState<boolean>(false);
  // vals
  const alarmDropdownText =
    alarmTime === null
      ? "시간 선택"
      : `${(~~(alarmTime / 60)).toString().padStart(2, "0")} : ${(
          alarmTime % 60
        )
          .toString()
          .padStart(2, "0")}`;

  // useEffect
  useEffect(() => {
    if (phoneNumber.length === 11) {
      setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"));
    } else if (phoneNumber.length === 13) {
      setPhoneNumber(
        phoneNumber
          //하이픈이 입력되면 공백으로 변경되고 하이픈이 다시 생성됨
          .replace(/-/g, "")
          .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      );
    }
  }, [phoneNumber]);

  useEffect(() => {
    const phoneReg = /^[0-1]{3}-[0-9]{4}-[0-9]{4}$/;
    if (phoneReg.test(phoneNumber) && alarmTime !== null && isAgree) {
      setIsClear(true);
    } else {
      setIsClear(false);
    }
  }, [phoneNumber, alarmTime, isAgree]);
  return (
    <div
      className="fixed top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-black-d/40"
      style={{ display: isOpen ? undefined : "none" }}
    >
      <div className="flex w-[343px] relative flex-col items-center bg-white-l px-[12px] rounded-[8px]">
        <button
          className="flex absolute top-[12px] right-[12px] z-10 "
          onClick={() => onCancel()}
        >
          <img src={imgUtil.icons.cross} className="w-[28px] h-[28px]" />
        </button>
        <Margin margin={30} />
        <div className="relative flex">
          <p className="text-[22px] font-bold text-black-d">챌린지 알림받기</p>
          <div className="flex absolute top-[-19px] left-[-15px]">
            <img src={imgUtil.logo_transparent} className="w-[32px] h-[32px]" />
          </div>
        </div>
        <Margin margin={2} />
        <p className="text-[14px] text-black-m ">
          인증시간이 되면 제일 먼저 알려드릴게요!
        </p>
        <Margin margin={3} />
        <p className="text-[12px] text-white-d ">*알림은 1회만 전송됩니다</p>
        <Margin margin={15} />
        {/* Input Form */}
        <div className="flex flex-col px-[18px] items-center">
          <InputForm text={"시간 입력"}>
            <div className="flex flex-1 w-[225px]">
              <Dropdown
                title={alarmDropdownText}
                dropdownItems={dropdownItems}
                isPlaceHolderVisible={alarmTime === null}
              />
            </div>
          </InputForm>
          <Margin margin={18} />
          <InputForm text={"전화번호"}>
            <div className="flex items-center justify-center bg-white-m px-[10px] rounded-[8px] py-[10px] flex-1 w-[225px] ">
              <input
                value={phoneNumber}
                placeholder={"010-0000-0000"}
                className="flex w-full h-full bg-transparent outline-none text-black-d  text-[14px] whitespace-nowrap   text-center border-0 font-regular placeholder:text-white-d "
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </InputForm>
          <Margin margin={18} />
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row items-center">
              <CheckBox isChecked={isAgree} setIsChecked={setIsAgree} />
              <Margin isHorizontal margin={6} />
              <p className="text-[12px] text-black-m ">
                개인정보처리방침 (필수)
              </p>
            </div>
            <button
              onClick={() =>
                window.open(
                  "https://pipit.oopy.io/363be37d-2dc0-4e25-8527-8e9b5cffb339"
                )
              }
            >
              <p className="font-light text-[12px] text-black-m underline">
                약관보기
              </p>
            </button>
          </div>
          <Margin margin={24} />
          <button
            onClick={isClear ? onComplete : () => null}
            className="flex bottom-[30px] rounded-[30px] py-[8px]  items-end justify-center w-full"
            style={{
              backgroundColor: isClear ? colors.pipit : colors.disablePipit,
            }}
          >
            <p className="text-[16px] font-bold text-white-l mr-[8px]">
              알림 설정 완료!
            </p>
          </button>
        </div>
        <Margin margin={12} />
        <p className="text-[12px] text-white-d">
          *알림시각을 변경하고싶다면 다시 신청해주세요!
        </p>
        <Margin margin={24} />
      </div>
    </div>
  );
};

interface InputFormProps {
  text: string;
  children: ReactNode;
}
const InputForm = ({ text, children }: InputFormProps) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <p className="text-[14px] font-bold">{text}</p>
      <Margin isHorizontal margin={15} />

      {children}
    </div>
  );
};

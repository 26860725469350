import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import { Route, Routes } from "react-router-dom";
import { setContext } from "@apollo/client/link/context";
import ReactGA from "react-ga4";

import { HomeContainer, MainContainer } from "./container";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import NewHomePagePresenter from "./presenter/NewHomePagePresenter";
import { isDev } from "./constants";
import { HelmetProvider } from "react-helmet-async";

declare global {
  interface Window {
    Kakao: any;
    fbq: any;
  }
}

const httpLink = createHttpLink({
  uri: isDev
    ? process.env.REACT_APP_SERVER_URL_DEV
    : process.env.REACT_APP_SERVER_URL_PROD,
});
// https://www.apollographql.com/docs/react/networking/authentication/ 여기 보고했음
const authLink = setContext((_, { headers }) => {
  const token = isDev
    ? process.env.REACT_APP_APOLLO_TOKEN_DEV
    : process.env.REACT_APP_APOLLO_TOKEN_PROD;
  return {
    headers: {
      ...headers,
      authorization: token || "",
    },
  };
});
const apolloLink = authLink.concat(httpLink);

function App() {
  // initializing
  dayjs.extend(relativeTime);
  dayjs.extend(duration);
  dayjs.locale("ko");

  useEffect(() => {
    // init kakao
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_API_KEY);
    }
    // init setting Cache
    settingCache();

    // init react-ga4
    const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
    if (trackingId) {
      ReactGA.initialize([
        {
          trackingId,
        },
      ]);
    }
  }, []);

  // end of initializing

  // state
  const [client, setClient] =
    useState<ApolloClient<NormalizedCacheObject> | null>(null);

  const settingCache = async () => {
    const cache = new InMemoryCache();
    setClient(
      new ApolloClient({
        link: apolloLink,
        cache,
      })
    );
  };

  if (!client) {
    return null;
  }

  return (
    <HelmetProvider>
      <ApolloProvider client={client}>
        <Routes>
          <Route path="Challenge" element={<MainContainer />} />
          <Route path="/" element={<HomeContainer />} />
        </Routes>
      </ApolloProvider>
    </HelmetProvider>
  );
}

export default App;

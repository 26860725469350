import { useState } from "react";
import { DropdownItems } from "~/components/Dropdown";

interface Output {
  isAlarmModalOpen: boolean;
  setIsAlarmModalOpen: (v: boolean) => void;
  phoneNumber: string;
  setPhoneNumber: (v: string) => void;
  alarmTime: number | null;
  setAlarmTime: (v: number | null) => void;
  dropdownItems: DropdownItems[];
}

export const useChallengeAlarmModal = (): Output => {
  // states
  const [isAlarmModalOpen, setIsAlarmModalOpen] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [alarmTime, setAlarmTime] = useState<number | null>(null); // 3시 30분 ~ 8시 30분, 0시 기준 210M ~ 510M 까지 30단위

  const dropdownItems: DropdownItems[] = Array.from(
    { length: 10 },
    (_, i) => 240 + 30 * i
  ).map((e) => {
    const hour = ~~(e / 60);
    const minute = e % 60;

    return {
      text: `${hour.toString().padStart(2, "0")}:${minute
        .toString()
        .padStart(2, "0")}`,
      onClick: () => setAlarmTime(e),
    };
  });

  return {
    dropdownItems,
    isAlarmModalOpen,
    setIsAlarmModalOpen,
    phoneNumber,
    setPhoneNumber,
    alarmTime,
    setAlarmTime,
  };
};

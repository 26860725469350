import { gql, useQuery } from "@apollo/client";
import { Query } from "~/generated/graphql";

export interface UseGetChallengeInfoOutput {
  isInfoQueryLoading: boolean;
  allCertiUserNum: number;
  todayCertiUserNum: number;
  reservedUserNum: number;
  isPostingTime: boolean;
  drawingLotDate: string;
  endDuration: string;
  startDuration: string;
  startTime: string;
}

const GET_CHALLENGE_INFO = gql`
  query getChallengeInfo {
    getChallengeTime {
      drawingLotDate
      endDuration
      startDuration
      startTime
    }
    getAllCertiUserNum
    getTodayCertiUserNum
    getReservedUserNum
    getIsPostingTime
  }
`;

export const useGetChallengeInfo = (): UseGetChallengeInfoOutput => {
  const { data, loading } = useQuery<Query>(GET_CHALLENGE_INFO, {
    fetchPolicy: "network-only",
    onCompleted: (d) => {
      // console.log(d);
    },
    onError: (e) => {
      alert("오류가 발생했어요");
      // console.log(e);
    },
  });

  const allCertiUserNum = data?.getAllCertiUserNum ?? -1;
  const todayCertiUserNum = data?.getTodayCertiUserNum ?? -1;
  const reservedUserNum = data?.getReservedUserNum ?? -1;
  const isPostingTime = data?.getIsPostingTime ?? false;
  const drawingLotDate = data?.getChallengeTime.drawingLotDate ?? "";
  const endDuration = data?.getChallengeTime.endDuration ?? "";
  const startDuration = data?.getChallengeTime.startDuration ?? "";
  const startTime = data?.getChallengeTime.startTime ?? "";

  return {
    isInfoQueryLoading: loading,
    allCertiUserNum,
    todayCertiUserNum,
    reservedUserNum,
    isPostingTime,
    drawingLotDate,
    endDuration,
    startDuration,
    startTime,
  };
};

import React from "react";

interface Props {
  isHorizontal?: boolean;
  margin: number;
}

export default ({ isHorizontal = false, margin }: Props) => {
  if (isHorizontal) {
    return <div style={{ width: margin, height: "100%" }} />;
  } else {
    return <div style={{ height: margin, width: "100%" }} />;
  }
};

import { useState } from "react";
import AWS, { Credentials, S3 } from "aws-sdk";
import dayjs from "dayjs";

export interface UploadProp {
  file: File;
  type: string;
  extension: string;
}

interface useUploadOutPut {
  isLoading: boolean;
  uploadToS3: (file: UploadProp) => Promise<string>;
}

export const useUpload = (): useUploadOutPut => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [_, setUrlString] = useState<string | undefined>(undefined);
  // S3로 업로드 하는 함수
  const uploadToS3 = async ({
    file,
    type,
    extension,
  }: UploadProp): Promise<string> => {
    // key
    const S3_ACESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY_ID;
    const SECRET_ACCESS_KEY = process.env.REACT_APP_S3_SECRET_ACCESS_KEY;

    setIsLoading(true);
    // const { extension, type } = await getBase64FromUrl(uri);
    // const arrayBuffer = decode(base64);
    // TODO: 고유 키값으로 중복 안 되도록 할 필요 있음.
    const name = `${dayjs().unix()}_time_stamp_v`;
    const contentDisposition = `inline;filename="${name}"`;
    const params: S3.PutObjectRequest = {
      Bucket: "alarm-app/prod/v-photo",
      Key: name + extension,
      Body: file,
      ContentDisposition: contentDisposition,
      ContentType: type,
      ACL: "public-read",
    };

    AWS.config.update({
      region: "ap-northeast-2",
      credentials: new Credentials({
        accessKeyId: S3_ACESS_KEY as string,
        secretAccessKey: SECRET_ACCESS_KEY as string,
      }),
    });

    return new Promise((resolver, reject) => {
      const s3Object = new AWS.S3();
      s3Object.upload(params, (err: Error, data: S3.ManagedUpload.SendData) => {
        setIsLoading(false);
        if (err) {
          // console.log("error : ", err);
          reject("error in callback");
        } else {
          setUrlString(data.Location);
          resolver(data.Location);
        }
      });
    });
  };

  return {
    isLoading,
    uploadToS3,
  };
};

export const KAKAO_SHARE_LINK = "https://bit.ly/3SY9cF1";

export const shareKakao = (url: string) => {
  window.Kakao.Link.sendDefault({
    objectType: "feed",
    content: {
      title: "「나도 했다」 미라클모닝 챌린지",
      description: "4-9시 사이에 사진만 인증하면 성공!",
      imageUrl:
        "https://alarm-app.s3.ap-northeast-2.amazonaws.com/site/you_can_miracle.png",
      link: {
        mobileWebUrl: url,
        webUrl: url,
      },
    },
    buttons: [
      {
        title: "도전하러 가기🚀",
        link: {
          mobileWebUrl: url,
          webUrl: url,
        },
      },
    ],
  });
};

export const openInstagram = () => {
  window.open("https://www.instagram.com/pipit_morning/");
};
export const openTwitter = () => {
  window.open("https://twitter.com/pipit_morning");
};

export const openNotion = () => {
  window.open("https://pipit.oopy.io/");
};

export const openAppStore = () => {
  window.open("https://apps.apple.com/kr/app/id1631253620");
};

export const openPlayStore = () => {
  window.open("https://play.google.com/store/apps/details?id=com.im.pipit");
};

import React from "react";
import dayjs, { Dayjs } from "dayjs";

import {
  CustomFade,
  IconButton,
  JoinChallenge,
  Layout,
  Margin,
  RoundButton,
  RoundButtonBorder,
  SocialButton,
} from "~/components";
import { imgUtil } from "~/images/image_container";
import { challengeUrl, colors } from "~/constants";
import { JoinChallengeInfo, Status } from "~/container/ChallengeContainer";
import {
  KAKAO_SHARE_LINK,
  openInstagram,
  openTwitter,
  shareKakao,
} from "~/utils/share";
import CopyToClipboard from "react-copy-to-clipboard";
import { UseGetChallengeInfoOutput } from "~/hooks";
import { gaEvent } from "~/utils/ga";

export const StickyHeaderHeight = 40;

interface Props {
  status: Status;
  joinInfoMap: Map<Status, JoinChallengeInfo>;
  imgInputRef: React.RefObject<HTMLInputElement>;
  setSelectedImg: (file: File | undefined) => void;
  cardImg: string | null;
  now: Dayjs;
  useGetChallengeInfoOutput: UseGetChallengeInfoOutput;
  getTargetDate: (now: Dayjs) => Dayjs;
  openAlarmModal: () => void;
}

export default ({
  status,
  joinInfoMap,
  imgInputRef,
  setSelectedImg,
  cardImg,
  now,
  useGetChallengeInfoOutput,
  getTargetDate,
  openAlarmModal,
}: Props) => {
  // val
  const {
    drawingLotDate,
    startDuration,
    endDuration,
    todayCertiUserNum,
    reservedUserNum,
  } = useGetChallengeInfoOutput;

  return (
    <Layout backgroundColor={colors.white.back}>
      {/* 챌린지 참여 */}
      <JoinChallenge
        status={status}
        targetDate={getTargetDate(now)}
        peopleNum={
          status === "NOT_OPENED" ? reservedUserNum : todayCertiUserNum
        }
        joinInfoMap={joinInfoMap}
        isDark={status === "NOT_OPENED" || status === "OUT_OF_TIME"}
        imgInputRef={imgInputRef}
        setSelectedImg={setSelectedImg}
        cardImg={cardImg}
        now={now}
      />

      {/* <div
        // 참여방법
        className="flex flex-col items-center px-[16px] bg-white-back w-full"
      >
        <Margin margin={40} />
        <CustomFade>
          <p className="text-[24px] font-gmarket font-bold text-black-d">
            ✨SNS 인증 EVENT✨
          </p>
        </CustomFade>
        <Margin margin={32} />

        <CustomFade
        //이벤트 설명
        >
          <div className="flex relative flex-col w-full rounded-[10px] border border-black-m px-[16px] py-[20px] bg-white-l">
            <div className="absolute flex flex-row justify-center w-full top-[-17px] mx-[-16px] ">
              <img
                src={imgUtil.illust.event_description_header}
                className=" w-[268px] h-[58px]"
              />
            </div>
            <Margin margin={44} />
            <div className="flex flex-row items-center ">
              <div className="flex px-[8px] py-[1px] bg-pipit w-fit items-center justify-center">
                <p className="text-[12px] text-white-l">참여기간</p>
              </div>
              <Margin margin={8} isHorizontal />
              <p className="text-[12px] text-pipit">
                {`${dayjs(startDuration).format("YYYY.MM.DD (ddd)")} ~ ${dayjs(
                  endDuration
                ).format("MM.DD (ddd)")}`}
              </p>
            </div>
            <Margin margin={24} />
            <div className="flex px-[8px] py-[1px] bg-pipit w-fit items-center justify-center">
              <p className="text-[12px] text-white-l">참여방법</p>
            </div>
            <Margin margin={8} />
            <p className="text-[12px] text-black-d ml-[6px]">
              1. 업로드한 이미지를 저장한다
            </p>
            <div className="flex ml-[6px]">
              <p className="text-[12px] text-black-d whitespace-pre">{"2. "}</p>
              <p className="text-[12px] text-black-d font-bold">
                Pipit 계정을 태그
              </p>
              <p className="text-[12px] text-black-d">
                해서 SNS에 이미지를 업로드한다
              </p>
            </div>
            <Margin margin={8} />
            <div className="flex flex-row justify-center w-full">
              <SocialButton
                iconSrc={imgUtil.icons.instagram}
                text={"@pipit_morning"}
                onClick={() => {
                  gaEvent({
                    status,
                    category: "Challenge",
                    action: `click_pipit_instagram`,
                  });

                  openInstagram();
                }}
              />
              <Margin isHorizontal margin={12} />
              <SocialButton
                iconSrc={imgUtil.icons.twitter}
                text={"@pipit_morning"}
                onClick={() => {
                  gaEvent({
                    status,
                    category: "Challenge",
                    action: `click_pipit_twitter`,
                  });
                  openTwitter();
                }}
              />
            </div>
            <Margin margin={24} />
            <div className="flex px-[8px] py-[1px] bg-pipit w-fit items-center justify-center">
              <p className="text-[12px] text-white-l">참여자 경품</p>
            </div>
            <Margin margin={8} />
            <p className="ml-[6px] text-[12px] text-pipit">
              1등(1명) : 현금 10만원💰
            </p>
            <Margin margin={8} />
            <p className="ml-[6px] text-[12px] text-black-d">
              2등(3명) : 현금 5만원💵
            </p>
            <Margin margin={8} />
            <p className="ml-[6px] text-[12px] text-black-d">
              3등(100명) : 커피 기프티콘☕️
            </p>
            <Margin margin={24} />
            <div className="flex flex-row items-center ">
              <div className="flex px-[8px] py-[1px] bg-pipit w-fit items-center justify-center">
                <p className="text-[12px] text-white-l">당첨자 발표</p>
              </div>
              <Margin margin={8} isHorizontal />
              <p className="text-[12px] text-pipit">
                {`${dayjs(drawingLotDate).format(
                  "YYYY.MM.DD (ddd)"
                )} 랜덤추첨 후 SNS공지`}
              </p>
            </div>
            <Margin margin={6} />
            <p className="flex text-black-l text-[12px]">
              🍯 당첨확률 높이는 Tip. 친구 태그하면 확률 up!
            </p>
          </div>
        </CustomFade>

        <Margin margin={32} />
      </div>
      <div className="flex w-full h-[8px] bg-white-m" /> */}
      <div className="flex flex-col items-center px-[16px] bg-white-back w-full">
        <Margin margin={36} />

        <RoundButton
          text={"인증 시간에 알림받기"}
          iconSrc={imgUtil.icons.bell}
          onClick={() => {
            openAlarmModal(),
              gaEvent({
                status,
                category: "Challenge",
                action: `open_alarm_modal`,
              });
          }}
        />
        {status !== "NOT_OPENED" && (
          <>
            <Margin margin={16} />
            <RoundButtonBorder
              text={"미라클 모닝하는 사람들 만나보기"}
              iconSrc={imgUtil.logo}
              onClick={() => {
                gaEvent({
                  status,
                  category: "Challenge",
                  action: `click_meet_miracle_morning`,
                });
                openInstagram();
              }}
            />
          </>
        )}
        <Margin margin={32} />
        <div className="flex flex-row items-center">
          <p className="text-[14px] text-black-l">미라클모닝 같이하기</p>
          <Margin isHorizontal margin={24} />
          <IconButton
            iconSrc={imgUtil.icons.kakao_black}
            onClick={() => {
              gaEvent({
                status,
                category: "Challenge",
                action: `click_kakao_share`,
              });
              shareKakao(KAKAO_SHARE_LINK);
            }}
          />
          <Margin isHorizontal margin={24} />
          <div className="h-[16px] w-[0.5px] bg-black-l/50" />
          <Margin isHorizontal margin={24} />
          <CopyToClipboard
            text={challengeUrl}
            onCopy={() => {
              gaEvent({
                status,
                category: "Challenge",
                action: `click_link_copy`,
              });
              alert(`복사가 완료되었습니다!`);
            }}
          >
            <IconButton
              iconSrc={imgUtil.icons.link_black}
              onClick={() => null}
            />
          </CopyToClipboard>
        </div>
        <Margin margin={64} />
      </div>
    </Layout>
  );
};

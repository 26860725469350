import React from "react";
import { CustomFade, Layout, Margin } from "~/components";
import { imgUtil } from "~/images/image_container";

interface Props {
  onPressEnter: () => void;
}

export default ({ onPressEnter }: Props) => {
  return (
    <Layout>
      <div className="px-[16px] bg-white-l w-full h-screen flex-col flex justify-between">
        <div>
          <Margin margin={16} />
          <CustomFade>
            <img src={imgUtil.logo} className="w-[62px] h-[62px]" />
            <Margin margin={5} />
            <p className="text-black-back text-[44px] font-gmarket font-bold">
              #야 #너두
            </p>
            <p className="text-pipit text-[44px] font-gmarket font-bold">
              #미라클모닝
            </p>
            <p className="text-black-back text-[44px] font-gmarket font-bold">
              #할수있어
            </p>
          </CustomFade>
          <Margin margin={8} />
          <CustomFade>
            <p className="text-black-d text-[18px] font-medium font-gmarket whitespace-pre-line">
              누구나 참여하는
            </p>
            <p className="text-black-d text-[18px] font-medium font-gmarket whitespace-pre-line">
              미라클모닝 챌린지
            </p>
          </CustomFade>
        </div>
        <button onClick={onPressEnter}>
          <CustomFade>
            <Margin margin={44} />
            <div className="flex justify-end w-full">
              <img src={imgUtil.post_sample} className="w-[200px] h-[258px]" />
            </div>
            <Margin margin={10} />
            <div className="flex justify-end w-full">
              <div className="flex py-[4px] px-[10px] bg-black-l rounded-[23px] items-center ">
                <p className="text-[12px] text-white-l">
                  터치해서 챌린지 참여하기
                </p>

                <Margin isHorizontal margin={4} />
                <img
                  src={imgUtil.icons.white_triangle}
                  className="w-[9px] h-[9px]"
                />
              </div>
              <Margin isHorizontal margin={18} />
            </div>
            <Margin margin={54} />
          </CustomFade>
        </button>
      </div>
    </Layout>
  );
};

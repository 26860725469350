export const isDev = process.env.REACT_APP_NODE_ENV === "dev" ? true : false;
export const colors = {
  pipit: "#FF8624",
  disablePipit: "#FFCFA7",
  white: {
    back: "#F9F9F9",
    d: "#C3C3C3",
    m: "#F1F1F1",
    l: "#FFFFFF",
  },

  black: {
    back: "#212121",
    d: "#262626",
    m: "#484848",
    l: "#7B7B7B",
  },
};

export const challengeUrl = "https://pipit.im/challenge";

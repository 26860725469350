import React from "react";
import { imgUtil } from "~/images/image_container";
import { classNames } from "~/utils";

interface Props {
  isChecked: boolean;
  setIsChecked: (v: boolean) => void;
}

export default ({ isChecked, setIsChecked }: Props) => {
  return (
    <div
      onClick={() => setIsChecked(!isChecked)}
      className={classNames(
        "flex w-[16px] h-[16px] rounded-[2.5px] items-center justify-center ",
        isChecked ? "bg-pipit" : "bg-white-m"
      )}
    >
      {isChecked && (
        <img src={imgUtil.icons.check} className="w-[10px] h-[10px]" />
      )}
    </div>
  );
};

import React from "react";
import { colors } from "~/constants";

interface Props {
  backgroundColor?: string;
  children: React.ReactNode;
}
export default ({ children, backgroundColor: backgroundColorProps }: Props) => {
  const backgroundColor = backgroundColorProps ?? colors.white.l;
  return (
    <div
      className="flex flex-col items-center flex-1 "
      style={{ backgroundColor }}
    >
      <div className="flex relative flex-col  max-w-[640px] w-full  leading-normal font-NotoSans">
        {children}
      </div>
    </div>
  );
};

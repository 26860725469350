import React, { ReactNode } from "react";
import { Fade } from "react-reveal";

export default ({ children }: { children: ReactNode }) => {
  return (
    <Fade bottom distance={"50px"} duration={500}>
      {children}
    </Fade>
  );
};

import dayjs from "dayjs";
import React, { ReactNode, useEffect } from "react";
import { Link } from "react-router-dom";
import { CustomFade, Margin } from "~/components";
import { useScrollBlock } from "~/hooks";
import { imgUtil } from "~/images/image_container";
import { classNames } from "~/utils";
import { gaEvent } from "~/utils/ga";
import {
  openAppStore,
  openInstagram,
  openNotion,
  openPlayStore,
  openTwitter,
} from "~/utils/share";

interface Props {
  endDuration: string;
}

export default ({ endDuration }: Props) => {
  const now = dayjs();
  const fixedHeaderHeight = 56;
  return (
    <div className="flex flex-col items-center w-full bg-white-l ">
      <div className="fixed top-0 z-10 flex w-full bg-red-400">
        <nav
          className="flex fixed w-full py-[14px]  flex-col items-center shadow-sm border-b border-white-m  bg-white-l md:px-[32px] lg:px-[32px] px-[8px] "
          style={{ height: fixedHeaderHeight }}
        >
          {/* 헤더 */}
          <div className="flex flex-row justify-between max-w-[1024px] w-full  ">
            <a href="http://pipit.im">
              <img
                src={imgUtil.homePage.pipit_logo_text}
                className="w-[90px] h-[34px]"
              />
            </a>
            <div className="flex flex-row lg:gap-[32px] md:gap-[32px] gap-[16px]">
              <button
                className="w-[28px] h-[28px]"
                onClick={() => {
                  gaEvent({ category: "/", action: "click_notion" });
                  openNotion();
                }}
              >
                <img src={imgUtil.homePage.notion_icon} alt="" />
              </button>
              <button
                className="w-[28px] h-[28px]"
                onClick={() => {
                  gaEvent({ category: "/", action: "click_instagrm" });
                  openInstagram();
                }}
              >
                <img src={imgUtil.homePage.instagram_icon} alt="" />
              </button>
              <button
                className="w-[28px] h-[28px]"
                onClick={() => {
                  gaEvent({ category: "/", action: "click_twitter" });
                  openTwitter();
                }}
              >
                <img src={imgUtil.homePage.twitter_icon} alt="" />
              </button>
            </div>
          </div>
        </nav>
      </div>
      <Margin margin={fixedHeaderHeight} />
      <img
        src={imgUtil.homePage.header_background}
        className="flex h-[880px] absolute  w-full"
        style={{ top: fixedHeaderHeight }}
      />

      <div className="flex relative w-full lg:max-w-[1024px] max-w-[390px] flex-col justify-center items-center ">
        {/* 헤더 이미지 */}
        <img
          src={imgUtil.homePage.header_phone}
          className="lg:w-[543px] right-0 lg:absolute hidden lg:flex"
          style={{ top: fixedHeaderHeight }}
        />
        {/* 타이틀, 로고 */}
        <div className="flex flex-col items-center md:items-start lg:items-start absolute top-0 lg:left-[72px] lg:top-[109px] lg:max-w-[1024px] mt-[92px] lg:mt-0">
          <h1 className="font-bold text-[34px] md:text-[48px] lg:text-[60px] text-black-d font-Sebang mb-[12px]">
            우리 같이 일어나요!
          </h1>
          <h2 className="md:text-[28px] text-[20px] text-black-d font-gmarket mb-[32px]">
            성공적인 아침을 시작하는 SNS
          </h2>
          <img
            src={imgUtil.homePage.white_logo}
            className="w-[193px] lg:mb-[264px] mb-0"
          />
          <img
            src={imgUtil.homePage.header_phone}
            className="lg:hidden -mt-[32px]"
            style={{ top: fixedHeaderHeight }}
          />
          <div className="flex-col hidden md:flex lg:flex ">
            <p className=" text-[24px] text-black-m font-gmarket mb-[32px]">
              사람들과 함께 더 즐거운 아침 만들기
            </p>
            <div className="flex flex-row gap-[24px]">
              <button onClick={openPlayStore}>
                <img
                  src={imgUtil.homePage.google_banner}
                  className="w-[206px] aspect-auto shadow-lg rounded-[12px]"
                />
              </button>
              <button onClick={openAppStore}>
                <img
                  src={imgUtil.homePage.apple_banner}
                  className="w-[206px] aspect-auto shadow-lg rounded-[12px]"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* 이미지 헤더 */}
      <div className="lg:flex w-full h-[880px] flex " />
      <section className="flex relative flex-col w-full  lg:max-w-[1024px] lg:mt-[264px] md:mt-[240px] md:pb-[254px] md:gap-[300px] gap-[110px]  items-center">
        <HomeElement
          imgSrc={imgUtil.homePage.home_01}
          imgWidth={444.25}
          isTextLeft={false}
        >
          <div className="flex flex-col items-center">
            <div className="flex flex-row">
              <p className=" font-Sebang lg:text-[40px] text-[28px] md:text-[40px] font-bold text-pipit">
                부지런한 사람들
              </p>
              <p className=" font-Sebang lg:text-[40px] text-[28px] md:text-[40px] font-bold text-black-d">
                의
              </p>
            </div>
            <p className=" font-Sebang lg:text-[40px] text-[28px] md:text-[40px] font-bold text-black-d">
              모닝루틴을 구경해요
            </p>
            <Margin margin={24} />
            <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-black-d">
              다들 일찍 일어나서 뭐할까?
            </p>
            <div className="flex flex-row">
              <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-black-d whitespace-pre">
                {"궁금하다면 "}
              </p>
              <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-pipit">
                피드
              </p>
              <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-black-d">
                를 둘러보세요
              </p>
            </div>
          </div>
        </HomeElement>
        <HomeElement
          imgSrc={imgUtil.homePage.home_02}
          imgWidth={527.5}
          isTextLeft={true}
        >
          <div className="flex flex-col items-center">
            <p className=" font-Sebang lg:text-[40px] text-[28px] md:text-[40px] font-bold text-pipit">
              얼른 일어나요!
            </p>
            <p className=" font-Sebang lg:text-[40px] text-[28px] md:text-[40px] font-bold text-black-d">
              늦잠자면 서로 깨워줘요
            </p>
            <Margin margin={24} />
            <div className="flex flex-row">
              <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-black-d whitespace-pre">
                {"기분좋은 하루를 만드는 "}
              </p>
              <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-pipit ">
                응원하기
              </p>
            </div>
            <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-black-d">
              함께의 가치는 큰 힘을 만들어요
            </p>
          </div>
        </HomeElement>
        <HomeElement
          imgSrc={imgUtil.homePage.home_03}
          imgWidth={641.5}
          isTextLeft={false}
        >
          <div className="flex flex-col items-center">
            <p className=" font-Sebang lg:text-[40px] text-[28px] md:text-[40px] font-bold text-black-d">
              일어나자마자
            </p>
            <div className="flex flex-row">
              <p className=" font-Sebang lg:text-[40px] text-[28px] md:text-[40px] font-bold text-pipit">
                나의 아침
              </p>
              <p className=" font-Sebang lg:text-[40px] text-[28px] md:text-[40px] font-bold text-black-d">
                을 기록해요
              </p>
            </div>
            <Margin margin={24} />
            <div className="flex flex-row">
              <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-black-d whitespace-pre">
                {"다양한 "}
              </p>
              <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-pipit">
                타임스탬프
              </p>
              <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-black-d">
                로
              </p>
            </div>
            <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-black-d">
              쉽고 빠르게 기록을 남겨보세요
            </p>
          </div>
        </HomeElement>
        <HomeElement
          imgSrc={imgUtil.homePage.home_04}
          imgWidth={444.75}
          isTextLeft={true}
        >
          <div className="flex flex-col items-center">
            <div className="flex flex-row">
              <p className=" font-Sebang lg:text-[40px] text-[28px] md:text-[40px] font-bold text-pipit">
                꾸준한 기록들
              </p>
              <p className=" font-Sebang lg:text-[40px] text-[28px] md:text-[40px] font-bold text-black-d">
                을 돌아보면
              </p>
            </div>
            <p className=" font-Sebang lg:text-[40px] text-[28px] md:text-[40px] font-bold text-black-d">
              뿌듯함은 배가 될 거예요
            </p>
            <Margin margin={24} />
            <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-black-d">
              쌓여가는 기록은
            </p>
            <div className="flex flex-row">
              <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-pipit">
                프로필
              </p>
              <p className=" font-gmarket md:text-[28px] text-[20px] lg:text-[28px]  text-black-d">
                을 통해 볼 수 있어요
              </p>
            </div>
          </div>
        </HomeElement>
      </section>
      {/* {now.isBefore(endDuration) && (
        <Link
          className="flex w-full lg:py-[42px] md:py-[32px]  pt-[22px] pb-[14px] bg-pipit flex-row justify-center "
          to="/Challenge"
          onClick={() =>
            gaEvent({ category: "Home", action: "click_link_to_challenge" })
          }
        >
          <p className="font-gmarket lg:text-[36px] text-[16px]  md:text-[28px] font-white-l font-bold text-white-l">
            {"Pipit 미라클 모닝 챌린지 참여하기 >"}
          </p>
        </Link>
      )} */}
      <div className="flex w-full flex-col items-center bg-black-m pt-[92px] pb-[66px]">
        <div className="flex lg:flex-row flex-col lg:gap-[32px] gap-[16px]">
          <button
            onClick={() => {
              gaEvent({ category: "Home", action: "open_play_store" });
              openPlayStore();
            }}
          >
            <img
              src={imgUtil.homePage.google_banner}
              className="w-[240px] aspect-auto shadow-lg rounded-[12px]"
            />
          </button>
          <button
            onClick={() => {
              gaEvent({ category: "Home", action: "open_app_store" });
              openAppStore();
            }}
          >
            <img
              src={imgUtil.homePage.apple_banner}
              className="w-[240px] aspect-auto shadow-lg rounded-[12px]"
            />
          </button>
        </div>
        <Margin margin={60} />
        <img
          src={imgUtil.homePage.pipit_app_logo_text}
          className="aspect-auto w-[105px]"
        />
        <Margin margin={16} />
        <p className="text-[14px] text-white-l/70">
          ⓒ 2022. Pipit all rights reserved.
        </p>
      </div>
    </div>
  );
};

interface HomeElementProps {
  imgSrc: string;
  imgWidth: number;
  children: ReactNode;
  isTextLeft: boolean;
}

const HomeElement = ({
  imgSrc,
  imgWidth,
  children,
  isTextLeft,
}: HomeElementProps) => {
  return (
    <CustomFade>
      <div
        className={classNames(
          "flex lg:gap-[80px] gap-[40px] items-center",
          isTextLeft
            ? "lg:flex-row-reverse flex-col-reverse"
            : "lg:flex-row flex-col-reverse"
        )}
      >
        <img
          src={imgSrc}
          className="aspect-auto"
          style={{ width: imgWidth * 0.93 }}
        />
        {children}
      </div>
    </CustomFade>
  );
};

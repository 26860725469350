import { gql, useMutation } from "@apollo/client";
import { Mutation, MutationCreatePostDataArgs } from "~/generated/graphql";

const CREATE_POST_DATA = gql`
  mutation createPostData($postUrl: String!) {
    createPostData(postUrl: $postUrl) {
      id
      postUrl
    }
  }
`;

interface Output {
  createPostData: (postUrl: string) => Promise<boolean>;
}

export const useCreatePostData = (): Output => {
  const [createPostDataMutation, { data, loading }] = useMutation<
    Mutation,
    MutationCreatePostDataArgs
  >(CREATE_POST_DATA, {
    fetchPolicy: "network-only",
    onCompleted: (d) => {
      window.fbq("track", "Purchase");
      // console.log("createPostData : ", d);
    },
    onError: (err) => {
      // console.log("createPostData ERR : ", err);
      alert("오류가 발생했어요");
    },
  });

  const createPostData = (postUrl: string) => {
    return new Promise<boolean>((resolve, reject) => {
      createPostDataMutation({ variables: { postUrl } })
        .then((e) => resolve(!!e))
        .catch((err) => reject(err));
    });
  };

  return {
    createPostData,
  };
};

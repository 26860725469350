import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Margin } from "~/components";
import { colors } from "~/constants";
import { imgUtil } from "~/images/image_container";
interface RoundButtonProps {
  text: string;
  iconSrc?: string;
  onClick: () => void;
  backgroundColor?: string;
  isStationary?: boolean; // 안 따라다님!
  isWidthFull?: boolean;
}

export const RoundButton = ({
  text,
  iconSrc,
  onClick,
  backgroundColor: backgroundColorProps,
  isStationary = false,
  isWidthFull,
}: RoundButtonProps) => {
  // Sticky 코드 제거
  // const ref = useRef<HTMLButtonElement>(null);
  // const refDiv = useRef<HTMLDivElement>(null);
  const backgroundColor = backgroundColorProps
    ? backgroundColorProps
    : colors.pipit;

  // const [isFixed, setIsFixed] = useState<boolean>(false);

  // const listener = _.throttle(() => {
  //   if (isStationary) {
  //     return;
  //   }
  //   const height = ref.current?.getBoundingClientRect().height ?? 0;
  //   const bottomOffset =
  //     window.innerHeight - (ref.current?.getBoundingClientRect().bottom ?? 0);
  //   const divBottomOffset =
  //     window.innerHeight -
  //     (refDiv.current?.getBoundingClientRect().bottom ?? 0) -
  //     height;
  //   if (bottomOffset > 30) {
  //     setIsFixed(true);
  //   } else if (divBottomOffset < 30) {
  //     setIsFixed(false);
  //   }
  // }, 100);

  // useEffect(() => {
  //   window.addEventListener("scroll", listener);
  //   return () => window.removeEventListener("scroll", listener);
  // }, []);
  return (
    <>
      {/* <div ref={refDiv} className="" />
      {isFixed && (
        <div
          style={{
            height: ref.current?.getBoundingClientRect().height ?? 0,
            width: 1,
          }}
        />
      )} */}
      <button
        // ref={ref}
        onClick={onClick}
        className=" flex bottom-[30px] z-40 rounded-[30px] py-[15px]  items-end justify-center "
        style={{
          backgroundColor,
          width: isWidthFull ? "100%" : 326,
          // position: isFixed ? "fixed" : "static",
        }}
      >
        <p className="text-[18px] font-bold text-white-l mr-[8px]">{text}</p>
        {iconSrc && <img src={iconSrc} className="w-[24px] h-[24px]" />}
      </button>
    </>
  );
};

export const RoundButtonBorder = ({
  text,
  iconSrc,
  onClick,
  isWidthFull,
  backgroundColor: backgroundColorProps,
}: RoundButtonProps) => {
  // Sticky 코드 제거
  // const ref = useRef<HTMLButtonElement>(null);
  // const refDiv = useRef<HTMLDivElement>(null);
  const backgroundColor = backgroundColorProps
    ? backgroundColorProps
    : colors.white.l;

  // const [isFixed, setIsFixed] = useState<boolean>(false);

  // const listener = _.throttle(() => {
  //   const height = ref.current?.getBoundingClientRect().height ?? 0;
  //   const bottomOffset =
  //     window.innerHeight - (ref.current?.getBoundingClientRect().bottom ?? 0);
  //   const divBottomOffset =
  //     window.innerHeight -
  //     (refDiv.current?.getBoundingClientRect().bottom ?? 0) -
  //     height;
  //   if (bottomOffset > 30) {
  //     setIsFixed(true);
  //   } else if (divBottomOffset < 30) {
  //     setIsFixed(false);
  //   }
  // }, 100);

  // useEffect(() => {
  //   window.addEventListener("scroll", listener);
  //   return () => window.removeEventListener("scroll", listener);
  // }, []);
  return (
    <>
      {/* <div ref={refDiv} className="" /> */}
      {/* {isFixed && (
        <div
          style={{
            height: ref.current?.getBoundingClientRect().height ?? 0,
            width: 1,
          }}
        />
      )} */}

      <button
        // ref={ref}
        onClick={onClick}
        className=" flex border border-pipit bottom-[30px] z-40 rounded-[30px] py-[15px]  items-center justify-center "
        style={{
          // position: isFixed ? "fixed" : "static"
          backgroundColor,
          width: isWidthFull ? "100%" : 326,
        }}
      >
        {iconSrc && <img src={iconSrc} className="w-[29px] h-[29px]" />}
        <Margin isHorizontal margin={4} />
        <p className="text-[18px] font-bold text-pipit mr-[4px]">{text}</p>
        <img src={imgUtil.icons.arrow_orange} className="w-[18px] h-[18px]" />
      </button>
    </>
  );
};

interface SocialButtonProps {
  text: string;
  iconSrc: string;
  onClick: () => void;
}

export const SocialButton = ({ iconSrc, onClick, text }: SocialButtonProps) => {
  return (
    <button
      onClick={onClick}
      className="flex flex-row items-center rounded-[8px] py-[6px] px-[8px] bg-black-m shadow-md"
    >
      <img src={iconSrc} className="h-[24px] w-[24px]" />
      <Margin isHorizontal margin={2} />
      <p className="font-bold text-[12px] text-white-l">{text}</p>
    </button>
  );
};

interface IconButtonProps {
  iconSrc: string;
  onClick: () => void;
}

export const IconButton = ({ iconSrc, onClick }: IconButtonProps) => {
  return (
    <button onClick={onClick}>
      <img src={iconSrc} className="w-[26px] h-[26px]" />
    </button>
  );
};

interface TranslucenceProps {
  text: string;
  iconSrc: string;
  onClick: () => void;
  style?: React.CSSProperties;
}

export const TranslucenceButton = ({
  text,
  iconSrc,
  onClick,
  style,
}: TranslucenceProps) => {
  return (
    <button
      onClick={onClick}
      className="flex flex-row bg-black-d/40 rounded-[17px] py-[4px] px-[8px] items-center"
      style={style}
    >
      <img src={iconSrc} className="w-[20px] h-[20px]" />
      <Margin isHorizontal margin={2} />
      <p className="text-[12px] text-white-l">{text}</p>
    </button>
  );
};

import ReactGA from "react-ga4";
import { Status } from "~/container/ChallengeContainer";

interface GAEventProps {
  status?: Status;
  category: string;
  action: string;
}
export const gaEvent = ({ category, action, status }: GAEventProps) => {
  let header = "";
  if (status === "NOT_OPENED") {
    header = "openbe_";
  } else if (status === "OUT_OF_TIME") {
    header = "openaf_notauthtime_";
  } else if (status === "BEFORE_POST") {
    header = "openaf_beforeyet_authtime_";
  } else if (status === "AFTER_POST") {
    header = "openaf_after_authtime_";
  } else {
    header = "";
  }
  ReactGA.event({
    category,
    action: `${header}${action}`,
  });
};

import logo from "./challengePage/logo.png";
import logo_text from "./challengePage/logo_text.png";
import logo_transparent from "./challengePage/logo_transparent.png";

// dev
import transparent from "./transparent.png";

// post
import post_background from "./challengePage/post_background.png";
import post_background_80 from "./challengePage/post_background_80.png";
import post_background_no_text_bg from "./challengePage/post_background_no_text_bg.png";
import test_img from "./challengePage/test_img.png";
import default_post_img from "./challengePage/default_post_img.png";
import timeout_post_img from "./challengePage/timeout_post_img.png";
import post_sample from "./challengePage/post_sample.png";
import post_sample_upright from "./challengePage/post_sample_upright.png";

// icons
import icon_add_img from "./challengePage/icons/add_img.png";
import icon_triangle from "./challengePage/icons/triangle.png";
import icon_instagram from "./challengePage/icons/instagram.png";
import icon_twitter from "./challengePage/icons/twitter.png";
import icon_kakao from "./challengePage/icons/kakao.png";
import icon_link from "./challengePage/icons/link.png";
import icon_kakao_black from "./challengePage/icons/kakao_black.png";
import icon_link_black from "./challengePage/icons/link_black.png";
import icon_bell from "./challengePage/icons/bell.png";
import icon_time from "./challengePage/icons/time.png";
import icon_arrow_orange from "./challengePage/icons/arrow_orange.png";
import icon_download from "./challengePage/icons/download.png";
import icon_img from "./challengePage/icons/img.png";
import icon_white_pipit from "./challengePage/icons/white_pipit.png";
import icon_cross from "./challengePage/icons/cross.png";
import icon_check from "./challengePage/icons/check.png";
import icon_white_triangle from "./challengePage/icons/white_triangle.png";
import icon_dark_re_triangle from "./challengePage/icons/dark_re_triangle.png";

// illustrations
import illust_sun from "./challengePage/illustration/sun.png";
import illust_pipit from "./challengePage/illustration/pipit.png";
import illust_cloud_blue from "./challengePage/illustration/cloud_blue.png";
import illust_cloud_skyblue from "./challengePage/illustration/cloud_skyblue.png";
import illust_empty_sun from "./challengePage/illustration/empty_sun.png";
import illust_fireworks from "./challengePage/illustration/fireworks.png";
import illust_event_description_header from "./challengePage/illustration/event_description_header.png";
import illust_oops_pipit from "./challengePage/illustration/oops_pipit.png";

// prize card
import coffee from "./challengePage/coffee.png";
import money from "./challengePage/money.png";
import much_money from "./challengePage/much_money.png";

// home page
import twitter_icon from "./homePage/twitter_icon.png";
import pipit_logo_text from "./homePage/pipit_logo_text.png";
import notion_icon from "./homePage/notion_icon.png";
import pipit_app_logo_text from "./homePage/pipit_app_logo_text.png";
import instagram_icon from "./homePage/instagram_icon.png";
import home_04 from "./homePage/home_04.png";
import home_02 from "./homePage/home_02.png";
import home_03 from "./homePage/home_03.png";
import home_01 from "./homePage/home_01.png";
import header_background from "./homePage/header_background.png";
import google_banner from "./homePage/google_banner.png";
import apple_banner from "./homePage/apple_banner.png";
import white_logo from "./homePage/white_logo.png";
import header_phone from "./homePage/header_phone.png";

export const imgUtil = {
  transparent,
  logo,
  logo_text,
  logo_transparent,
  post_background,
  post_background_80,
  post_background_no_text_bg,
  test_img,
  default_post_img,
  timeout_post_img,
  post_sample,
  post_sample_upright,

  icons: {
    add_img: icon_add_img,
    triangle: icon_triangle,
    instagram: icon_instagram,
    twitter: icon_twitter,
    kakao: icon_kakao,
    link: icon_link,
    kakao_black: icon_kakao_black,
    link_black: icon_link_black,
    bell: icon_bell,
    time: icon_time,
    arrow_orange: icon_arrow_orange,
    download: icon_download,
    img: icon_img,
    white_pipit: icon_white_pipit,
    cross: icon_cross,
    check: icon_check,
    white_triangle: icon_white_triangle,
    dark_re_triangle: icon_dark_re_triangle,
  },

  illust: {
    sun: illust_sun,
    pipit: illust_pipit,
    cloud_blue: illust_cloud_blue,
    cloud_skyblue: illust_cloud_skyblue,
    empty_sun: illust_empty_sun,
    fireworks: illust_fireworks,
    event_description_header: illust_event_description_header,
    oops_pipit: illust_oops_pipit,
  },

  coffee,
  money,
  much_money,

  homePage: {
    twitter_icon,
    pipit_logo_text,
    notion_icon,
    pipit_app_logo_text,
    instagram_icon,
    home_04,
    home_02,
    home_03,
    home_01,
    header_background,
    google_banner,
    apple_banner,
    white_logo,
    header_phone,
  },
};

import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";

import { useChallengeAlarmModal } from "~/hooks";
import { useGetChallengeInfo } from "~/hooks/useGetChallengeInfo";
import { usePosting } from "~/hooks/usePosting";
import { useReserveSMS } from "~/hooks/useReserveSMS";
import {
  ChallengeAlarmModal,
  ChallengeFrontPresenter,
  MainPresenter,
} from "~/presenter";
import { useLocation } from "react-router-dom";
import { gaEvent } from "~/utils/ga";

export type Status =
  | "NOT_OPENED"
  | "OUT_OF_TIME"
  | "BEFORE_POST"
  | "AFTER_POST";

export interface JoinChallengeInfo {
  // main
  buttonText: string;
  iconSrc: string;
  onClick: () => void;
  // card
  card_default_text: string;
  isTimeStampVisible: boolean;
  isTransparent: boolean;
  isNoTextBg: boolean;
}

export default () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, []);
  // hooks
  const useGetChallengeInfoOutput = useGetChallengeInfo();
  const { isPostingTime, endDuration, startDuration } =
    useGetChallengeInfoOutput;

  const {
    dropdownItems,
    isAlarmModalOpen,
    setIsAlarmModalOpen,
    phoneNumber,
    setPhoneNumber,
    alarmTime,
    setAlarmTime,
  } = useChallengeAlarmModal();
  const openAlarmModal = () => setIsAlarmModalOpen(true);
  const { reserveSMS } = useReserveSMS();

  const {
    joinInfoMap,
    status,
    setStatus,
    cardImg,
    setSelectedImg,
    imgInputRef,
  } = usePosting({ openAlarmModal });

  // states
  const [isFront, setIsFront] = useState<boolean>(true);
  const [now, setNow] = useState<Dayjs>(dayjs());

  // for debug
  // const startTime = 16;
  // const endTime = 21;
  const startTime = 4;
  const endTime = 9;

  // funcs
  const getTargetDate = (now: Dayjs) => {
    const am4 = now
      .clone()
      .set("hour", startTime)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0);
    const am9 = now
      .clone()
      .set("hour", endTime)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0);
    if (status === "OUT_OF_TIME") {
      if (now.isBefore(am4)) {
        // am4보다 이전이라면
        return am4;
      } else if (now.isAfter(am9)) {
        return am4.clone().add(1, "day");
      } else {
        // 예외처리
        return now;
      }
    } else if (status === "BEFORE_POST") {
      // before POST라면 이미 now는 am4 이후임. + am9 이후엔 OUT_OF_TIME으로 바뀜.
      return am9;
    } else if (status === "NOT_OPENED") {
      // NOT OPENED라면 start Duration까지 남은 시간
      return dayjs(startDuration);
    }

    // AFTER POST는 targetDate 안 씀. // 예외처리
    return now;
  };

  useEffect(() => {
    // pixel
    window.fbq("track", "PageView");

    // now
    const interval = setInterval(() => {
      setNow(dayjs());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const startDate = dayjs(startDuration);
    const endDate = dayjs(endDuration);
    if (status !== "AFTER_POST") {
      if (now.isAfter(startDate) && now.isBefore(endDate)) {
        // 진행중이라면
        // if (4 < now.get("hour") && now.get("hour") < 9) {
        if (startTime < now.get("hour") && now.get("hour") < endTime) {
          setStatus("BEFORE_POST");
        } else {
          setStatus("OUT_OF_TIME");
        }
      } else {
        setStatus("NOT_OPENED");
      }
    }
  }, [isPostingTime, startDuration, endDuration, now]);

  return (
    <>
      <Helmet>
        {/* <title>Pipit | 나도했다 미라클모닝 챌린지</title>
        <meta name="theme-color" content="#FFFFFF" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="아침 기상 챌린지" />
        <meta
          name="description"
          content={
            "미라클모닝 챌린지 진행 중! 4-9시 사이에 사진만 인증하면 성공"
          }
        />
        <meta
          property="og:image"
          content="https://alarm-app.s3.ap-northeast-2.amazonaws.com/site/SEO.png"
        />
        <meta
          name="keywords"
          content="아침,아침기상,아침스터디,아침인증,아침운동"
        />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" /> */}
      </Helmet>
      {isFront ? (
        <ChallengeFrontPresenter
          onPressEnter={() => {
            setIsFront(false);
            gaEvent({
              status,
              category: "Challenge",
              action: `click_front_img`,
            });
          }}
        />
      ) : (
        <>
          {/* <div className="flex flex-col justify-around w-full">
            <div className="flex flex-row flex-1">
              <div
                onClick={() => setStatus("NOT_OPENED")}
                className="flex justify-center flex-1 py-1 bg-red-400"
              >
                NOT_OPENED
              </div>
              <div
                onClick={() => setStatus("OUT_OF_TIME")}
                className="flex justify-center flex-1 py-1 bg-blue-400"
              >
                OUT_OF_TIME
              </div>
            </div>
            <div className="flex flex-row flex-1">
              <div
                onClick={() => setStatus("BEFORE_POST")}
                className="flex justify-center flex-1 py-1 bg-green-400"
              >
                BEFORE_POST
              </div>
              <div
                onClick={() => setStatus("AFTER_POST")}
                className="flex justify-center flex-1 py-1 bg-orange-400"
              >
                AFTER_POST
              </div>
            </div>
            <div className="flex flex-row flex-1">
              <div className="flex justify-center flex-1 p-1 text-[12px] bg-zinc-900 text-white-l">
                {`현재 상태 : ${status}`}
              </div>
            </div>
          </div> */}
          <MainPresenter
            status={status}
            joinInfoMap={joinInfoMap}
            imgInputRef={imgInputRef}
            setSelectedImg={setSelectedImg}
            cardImg={cardImg}
            now={now}
            useGetChallengeInfoOutput={useGetChallengeInfoOutput}
            getTargetDate={getTargetDate}
            openAlarmModal={openAlarmModal}
          />
          <ChallengeAlarmModal
            isOpen={isAlarmModalOpen}
            onCancel={() => setIsAlarmModalOpen(false)}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            dropdownItems={dropdownItems}
            alarmTime={alarmTime}
            onComplete={() => {
              window.fbq("track", "AddToWishlist");
              gaEvent({
                status,
                category: "Challenge",
                action: `reserveSMS`,
              });
              reserveSMS(phoneNumber, alarmTime).then(() => {
                setIsAlarmModalOpen(false);
                alert("알람 예약이 완료되었습니다");
              });
            }}
          />
        </>
      )}
    </>
  );
};

import { gql, useMutation } from "@apollo/client";
import { reject } from "lodash";
import { Mutation, MutationReserveSmsArgs } from "~/generated/graphql";

const RESERVE_SMS = gql`
  mutation reserveSMS($input: ReserveSMSInput!) {
    reserveSMS(input: $input)
  }
`;

interface Output {
  reserveSMS: (
    phoneNumber: string,
    alarmTime: number | null
  ) => Promise<boolean>;
}

export const useReserveSMS = (): Output => {
  const [reserveSMSMuation, { data, loading }] = useMutation<
    Mutation,
    MutationReserveSmsArgs
  >(RESERVE_SMS, {
    fetchPolicy: "network-only",
    onCompleted: (d) => {},
    onError: (err) => {
      alert("오류가 발생했어요");
    },
  });

  const reserveSMS = (phoneNumber: string, alarmTime: number | null) => {
    return new Promise<boolean>((resolve, reject) => {
      const regPhoneNumber = /^[0,1]{3}-[0-9]{4}-[0-9]{4}$/;
      if (!regPhoneNumber.test(phoneNumber) || alarmTime === null) {
        reject("INVALID INPUT");
        return;
      }
      reserveSMSMuation({
        variables: {
          input: {
            phoneNumber,
            reserveHour: ~~(alarmTime / 60),
            reserveMinute: alarmTime % 60,
          },
        },
      })
        .then((e) => {
          if (!!e) {
            resolve(!!e);
          } else {
            reject(`failed: ${!!e}`);
          }
        })
        .catch((err) => reject(err));
    });
  };

  return {
    reserveSMS,
  };
};
